
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'

import AddEditClient from '@/components/agency/client/AddEditClient.vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    AddEditClient
  },
  setup () {
    const editClient = ref<boolean>(true)
    return {
      editClient
    }
  }
})
