<template>
  <div class="main">
    <div class="clients content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <router-link to="/dashboard"><span>Dashboard</span></router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item> <router-link to="/clients"><span>Client</span></router-link> </a-breadcrumb-item>
        <a-breadcrumb-item> Edit </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="add">
            <a-button type="primary">
              <router-link to="/clients"><ArrowLeftOutlined /> Back</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <AddEditClient :editClient="editClient" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'

import AddEditClient from '@/components/agency/client/AddEditClient.vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    AddEditClient
  },
  setup () {
    const editClient = ref<boolean>(true)
    return {
      editClient
    }
  }
})
</script>

<style lang="scss">
.clients {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .add {
      float: right;
      .ant-btn {
        background-color: #7EAF1A;
        border-color: #7EAF1A;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
